import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['Python', 'OpenCV', 'Tensorflow', 'Pytorch', 'Flask' ,'Face Recognition', 'Voice Assistants', 'Object Detection', 'OCR', 'Image Captioning', 'Data Analytics', 'Mathematical Modeling', 'Data Analytics', 'HTML, CSS, JS', 'React'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div>
        <StyledText>
          <div>
            <p>
            I’m a senior at {' '}
            <a href="https://ducic.ac.in/" target="_blank" rel="noreferrer">Cluster Innovation Centre, 
            University of Delhi</a> pursuing B.Tech in Mathematical Innovations and Information Technology and minor in 
            Management and Economics. I actively work in the fields of computer vision, data science, AI &amp; ML, robotics &amp; automation and software development.

            </p>

            <p>
            Since childhood, I've been interested in using technology or traditional concepts
            to bring ideas to reality by building projects which are helpful in real life. 
            I'm very enthusiastic about finding solutions to interesting problems 
            irrespective of knowing how to solve them. I would learn 
            what is required to solve the problem and will provide a dependable 
            solution which would definitely solve the problem. Initially I have started working 
            in the field of Robotics. Then I have started working with technologies like computer vision 
            and data science and I have explored the scope of solving real life problems using Computer 
            Vision and Artificial Intelligence and Software development to build useful projects.
            </p>

            <p>
              My notable work experience includes working as an AI Software Developer at {' '}
              <a href="http://www.tecstarlabs.com/">Tecstar Labs</a> based in USA and my undergrad 1st year summer internship at,{' '}
              <a href="">DRDO</a> as a Research Intern. I’m also working on building a software to assist visually impaired at {' '}
        <a href="https://aiview.co.in/" target="_blank" rel="noreferrer">
          AiView
        </a> which encompasses features such as voice assistant, object detection, navigation, OCR, face recognition etc. At present, I'm working at {' '}
        <a href="https://hackerrank.com/" target="_blank" rel="noreferrer">
          HackerRank
        </a> as a software engineer intern and will be working with Developer Experience team on HackerRank for Work platform to improve plagiarism detection, proctoring tools and data analytics dashboard.
            </p>

            <p>Here are a few technologies I’ve been working with recently:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

      </div>
    </StyledAboutSection>
  );
};

export default About;
