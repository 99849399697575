import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M435.651,850.174l126.064-68.158V637.388L437.036,563.135,310.695,632.124V776.474Z"
          fill="currentColor"
        />
        
      </g>
    </g>
  </svg>
);

export default IconLogo;
